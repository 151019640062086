import { Options } from "$fresh/plugins/twind.ts";

export default {
  selfURL: import.meta.url,
  theme: {
    extend: {
      colors: {
        primary: "#2563eb",
        secondary: "#475569",
      },
    },
  },
} as Options;
